import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CircularProgress,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";

// Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { DataGrid } from "@mui/x-data-grid";

function SingleUser({ props }) {
  const { id } = useParams();
  const auth = localStorage.getItem("authToken");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [values, setValues] = useState(null);
  const [orderHistory, setOrderHistory] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (id) {
      getUser();
      getUserHistory();
    }
    if (auth) {
      const decodedJwt = JSON.parse(atob(auth.split(".")[1]));
      if (decodedJwt.exp * 1000 < Date.now()) {
        navigate("/sign-in");
      }
    }
  }, [id, auth]);

  const getUser = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/users/getuser`,
        {
          user_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      );
      if (response.data.success) {
        setValues(response.data.data);
        enqueueSnackbar("User Fetched successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Failed", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const getUserHistory = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/order/getinitialdata`,
        {
          user_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      );
      if (response.data.success) {
        setOrderHistory(response.data.data.orders);
        enqueueSnackbar("User Fetched successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Failed", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    setIsEditable(!isEditable);
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/users/updateuser`,
        {
          is_active: values.is_active,
          user_id: values.user_id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      );
      if (response.data.success) {
        enqueueSnackbar("User updated successfully", {
          variant: "success",
        });
        getUser();
      } else {
        enqueueSnackbar("Failed to update user", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const convertUTCtoIST = (utcDate) => {
    // const timeZone = 'Asia/Kolkata'; // IST time zone
    const timeZone = 'UTC';
    const zonedDate = toZonedTime((utcDate), timeZone); // Convert UTC to IST
    return format(zonedDate, 'yyyy-MM-dd HH:mm:ss'); 
  };

  const columns = [
    {
      field: "billing_id",
      headerName: "Order ID",
      width: 150,
    },
    {
      field: "mobile_no",
      headerName: "Phone No",
      width: 200,
      valueGetter: (params) => params.row?.mobile_no,
    },
   {
      field: "billing_amount",
      headerName: "Order Value",
      width: 200,
      valueGetter: (params) => params.row?.billing_amount,
      align: "center",
    },
   {
      field: "order_date",
      headerName: "Order Date",
      width: 200,
      valueGetter: (params) => convertUTCtoIST(params.row?.order_date), 
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      field: "onclick",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <strong>
          <MDButton
            variant="contained"
            size="small"
            onClick={() => {
              navigate(`/orders/${params.row.order_id}`);
            }}
          >
            Open
          </MDButton>
        </strong>
      ),
    },
  ];

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        handleSave(e);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [values]); 

  return (
    <DashboardLayout>
      <DashboardNavbar hidebreadcrumbTitle />
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <MDBox pt={3} pb={3}>
            <Grid container spacing={6}>
              {values && (
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="secondary"
                      borderRadius="lg"
                      coloredShadow="info"
                      display="flex"
                      justifyContent="space-between"
                    >
                      <MDTypography variant="h6" color="white">
                        {values.name}
                      </MDTypography>
                      {localStorage.getItem("role") === "admin" && (
                        <MDButton
                          onClick={() => setIsEditable(true)}
                          variant="outlined"
                          color="white"
                        >
                          Update
                        </MDButton>
                      )}
                    </MDBox>

                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Card variant="outlined">
                          <Grid
                            container
                            spacing={3}
                            style={{ padding: "35px" }}
                          >
                            <MDBox p={1} width="100%">
                              <MDInput
                                type="text"
                                label="Name"
                                value={values.name || ""}
                                name="name"
                                margin="dense"
                                fullWidth
                                disabled={!isEditable}
                                onChange={handleChange}
                              />
                            </MDBox>
                            <MDBox p={1} width="100%">
                              <MDInput
                                type="text"
                                label="User ID"
                                value={values.user_id || ""}
                                name="user_id"
                                margin="dense"
                                fullWidth
                                disabled
                              />
                            </MDBox>

                            <MDBox p={1} width="100%">
                              <MDInput
                                type="text"
                                label="Phone No"
                                value={values.phone_no || ""}
                                name="phone_no"
                                margin="dense"
                                fullWidth
                                disabled={!isEditable}
                                onChange={handleChange}
                              />
                            </MDBox>

                            <MDBox p={1} width="100%">
                              <FormControl
                                fullWidth
                                margin="dense"
                        
                              >
                                <InputLabel>Active Status</InputLabel>
                                <Select
                                  value={String(values.is_active) || "false"}
                                  name="is_active"
                                  label="Active Status"
                                  disabled={!isEditable}
                                  onChange={handleChange}
                                  sx={{ height: "30px" }}
                                >
                                  <MenuItem value="true">Active</MenuItem>
                                  <MenuItem value="false">Inactive</MenuItem>
                                </Select>
                              </FormControl>
                            </MDBox>

                            {isEditable && (
                              <MDBox p={1}>
                                <MDButton
                                  onClick={handleSave}
                                  variant="contained"
                                  color="primary"
                                >
                                  Update User
                                </MDButton>
                              </MDBox>
                            )}
                          </Grid>
                          <MDBox pt={3}>
                {loading1 ? (
                  <CircularProgress
                    style={{
                      marginTop: "80px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                    color="inherit"
                  />
                ) : (
                  <>
                    {orderHistory && (
                      <MDBox sx={{ height: 400 }} p={2}>
                        <DataGrid
                          getRowId={(row) => row.billing_id}
                          rows={orderHistory}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 50,
                              },
                            },
                          }}
                          pageSizeOptions={[5, 10, 20, 50]}
                          disableRowSelectionOnClick
                        />
                      </MDBox>
                    )}
                  </>
                )}
              </MDBox>
                        </Card>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              )}
            </Grid>
          </MDBox>
        </>
      )}
    </DashboardLayout>
  );
}

export default SingleUser;
