// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import { CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";

import axios from "axios";
import MDBadge from "components/MDBadge";

function AllUsers() {
  const auth = localStorage.getItem("authToken");

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    {
      field: "user_id",
      headerName: "User ID",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "phone_no",
      headerName: "Phone No",
      width: 200,
    },

    {
      field: "is_active",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <MDBadge
          badgeContent={params.row.is_active === "true" ? "Active" : "Inactive"}
          container
          color={params.row.is_active === "true" ? "success" : "error"}
        />
      ),
    },
    {
      field: "onclick",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <strong>
          <MDButton
            variant="contained"
            size="small"
            onClick={() => {
              navigate(`/users/${params.row.user_id}`);
            }}
          >
            Open
          </MDButton>
        </strong>
      ),
    },
  ];

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BASEURL}/users/getallusers`,
        null,
        {
          headers: { Authorization: `Bearer ${auth}` },
        }
      );
      if (res.data.success) {
        // setUsers(Object.values(res.data.data).map((item) => item));
        setUsers(Object.values(res.data.data).reverse().map((item) => item));
      } else {
        if (res.data.data === "no record found") {
          enqueueSnackbar(res.data.data, { variant: "info" });
        } else {
          console.log("Error Occurred");
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Users
                </MDTypography>
                {/* <MDBox className="ticket_btnsHeaderCont">
                  <MDButton
                    onClick={() => navigate("/users/add")}
                    variant="outlined"
                    color="white"
                  >
                    Add Product
                  </MDButton>
                </MDBox> */}
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <CircularProgress
                    style={{
                      marginTop: "80px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                    color="inherit"
                  />
                ) : (
                  <>
                    {users && (
                      <MDBox sx={{ height: 400 }} p={2}>
                        <DataGrid
                          getRowId={(row) => row.user_id}
                          rows={users}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 100,
                              },
                            },
                          }}
                         
                          disableRowSelectionOnClick
                        />
                      </MDBox>
                    )}
                  </>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AllUsers;
