import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CircularProgress,
  Grid,
  FormControlLabel,
  Typography,
  TextField,
  Chip,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";

// Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";


function AddArtist() {
  const auth = localStorage.getItem("authToken");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [values, setValues] = useState();

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setValues(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!values) {
      enqueueSnackbar("Please Provide All The Details !!!", {
        variant: "error",
      });
      return;
    }
    setLoading(true);

    await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/admin/product/addartist`,
      data: {name: values },
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar("Operation successful", {
            variant: "success",
          });

          navigate(`/artists`);
        } else {
          enqueueSnackbar("Operation failed", { variant: "error" });
        }
      })
      .catch((err) => {
        console.error("Error occurred:", err);
        enqueueSnackbar("Error occurred. Please try again.", {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        handleSubmit(e);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [values]); 

  return (
    <DashboardLayout>
      <DashboardNavbar hidebreadcrumbTitle />

      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                 Add Artist
                </MDTypography>
              </MDBox>

              <MDBox p={3} component="form" role="form">
                <MDInput
                  label="Name of artist"
                  value={values}
                  
                  onChange={handleChange}
                  fullWidth
                />
              </MDBox>

              <Grid container direction="row" justifyContent="flex-end" p={2}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={20} sx={{ color: "inherit" }} />
                  ) : (
                    "Add"
                  )}
                </MDButton>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddArtist;
