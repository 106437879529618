// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import { CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";

import axios from "axios";

function Chamber() {
  const auth = localStorage.getItem("authToken");

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    {
      field: "product_id",
      headerName: "Product ID",
      width: 100,
    },
    {
      field: "title",
      headerName: "Name",
      width: 200,
    },
    {
      field: "product_type",
      headerName: "Product Type",
      width: 100,
    },
    {
      field: "category",
      headerName: "Category",
      width: 100,
    },

    {
      field: "selling_price",
      headerName: "Selling Price",
      width: 100,
    },
    {
      field: "mrp",
      headerName: "MRP",
      width: 100,
    },
    {
      field: "qty_available",
      headerName: "Available",
      width: 75,
    },
    {
      field: "qty_sold",
      headerName: "Sold",
      width: 50,
    },
    {
      field: "onclick",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <strong>
          <MDButton
            variant="contained"
            size="small"
            onClick={() => {
              navigate(`/products/${params.row.product_id}`);
            }}
          >
            Open
          </MDButton>
          {localStorage.getItem('role') === 'admin' &&
          <MDButton
            variant="contained"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => {
              navigate(`/products/duplicate/${params.row.product_id}`);
            }}
          >
            Duplicate
          </MDButton>
    }
        </strong>
      ),
    },
  ];

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  console.log(products);

  const getProducts = async (e, constituency) => {
    const constituency_id = 1;
    const role = "po";

    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BASEURL}/admin/product/getallproductforadmin`,
        null,
        {
          headers: { Authorization: `Bearer ${auth}` },
        }
      );
      if (res.data.success) {
        // const dataArray = Object.values(data).map((item) => item);

        setProducts(Object.values(res.data.data).map((item) => item));
      } else {
        if (res.data.data === "no record found") {
          enqueueSnackbar(res.data.data, { variant: "info" });
        } else {
          console.log("Error Occurred");
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Products
                </MDTypography>
                <MDBox className="ticket_btnsHeaderCont">
                   {localStorage.getItem('role') === 'admin' &&
                  <MDButton
                    onClick={() => navigate("/products/add")}
                    variant="outlined"
                    color="white"
                  >
                    Add Product
                  </MDButton>
}
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <CircularProgress
                    style={{ marginTop: "80px" }}
                    color="inherit"
                  />
                ) : (
                  <>
                    {products && (
                      <MDBox sx={{ height: 400}} p={2}>
                        <DataGrid
                          getRowId={(row) => row.product_id}
                          rows={products}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 100,
                              },
                            },
                          }}
                         
                          disableRowSelectionOnClick
                        />
                      </MDBox>
                    )}
                  </>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Chamber;
