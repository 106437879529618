// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui material components
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

function JoinMembership() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        mx={2}
        mt={3}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
          Membership
        </MDTypography>
      </MDBox>
      <MDBox
        mx={2}
        mt={3}
        py={3}
        p={4}
        mb={2}
        variant="gradient"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" pb={2}>
          Become a Member
        </MDTypography>
        <Grid container direction="row" justifyContent="space-between" gap={3} pb={0.5}>
          <MDInput type="text" label="First Name" name="" margin="dense" sx={{ width: "35ch" }} />
          <MDInput type="text" label="Last Name" name="" margin="dense" sx={{ width: "35ch" }} />
          <MDInput type="number" label="Phone Number" name="" margin="dense" />
          <MDInput type="email" label="Email" name="" margin="dense" fullWidth />
          <MDInput
            type="text"
            label="Organization Name"
            name=""
            margin="dense"
            sx={{ width: "35ch" }}
          />
          <MDInput
            type="text"
            label="Organization Category"
            name=""
            margin="dense"
            sx={{ width: "35ch" }}
          />
          <MDInput type="text" label="Update Role" name="" margin="dense" />
        </Grid>
      </MDBox>
      <Grid container direction="row" justifyContent="flex-end" p={2} pb={0.5}>
        <MDButton variant="gradient" color="info">
          Join Membership
        </MDButton>
      </Grid>
    </DashboardLayout>
  );
}

export default JoinMembership;
