import React, { useState } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

const AddInventoryModal = ({ open, onOpen, onClose, productId }) => {
  const [numberInput, setNumberInput] = useState("");
  const [isLoading, setLoading] = useState(false);
  const auth = localStorage.getItem("authToken");

  const handleOpenDialog = () => {
    onOpen && onOpen();
  };

  const handleCloseDialog = () => {
    onClose && onClose();
  };

  const handleButtonClick = async () => {
    setLoading(true);
    axios({
        method: "put",
        url: `${process.env.REACT_APP_BASEURL}/admin/product/inventory/add`,
        data: {
          product_id: productId,
          qty: numberInput,
          user_id: 2,
        },
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          handleCloseDialog();
          window.location.reload();
        } else {
          console.error("API call failed");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>Add Inventory</DialogTitle>
      <DialogContent>
        <TextField
        style={{marginTop:'10px'}}
          label="Enter Stock Count"
          type="number"
          value={numberInput}
          onChange={(e) => setNumberInput(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          color="secondary"
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleButtonClick}
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddInventoryModal;
