// SelectArtist.js
import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useSnackbar } from "notistack";
import axios from "axios";
import { Chip } from "@mui/material";

const SelectArtist = ({
  name,
  selectedValues,
  setSelectedValues,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const auth = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchOptions = async () => {
      if (inputValue.length >= 3) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASEURL}/admin/product/getallartist`,
            {
              word: inputValue,
            },
            {
              headers: {
                Authorization: `Bearer ${auth}`,
              },
            }
          );

          if (response.data.success) {
            setOptions(response.data.data);
          } else {
            enqueueSnackbar("Failed", { variant: "error" });
          }
        } catch (error) {
          console.error(error);
          enqueueSnackbar("Error occurred. Please try again.", {
            variant: "error",
          });
        }
      } else {
        // Clear options if inputValue is less than 3 characters
        setOptions([]);
      }
    };

    fetchOptions();
  }, [inputValue]);

  const handleSelectionChange = (_, selectedOptions) => {
    setSelectedValues(selectedOptions);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      const newOption = { name: inputValue };
      setOptions([...options, newOption]);
      setSelectedValues([...selectedValues, newOption]);
      setInputValue("");
    }
  };

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option) => option.name}
      value={selectedValues}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip label={option.name} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={name}
          variant="outlined"
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      )}
      onChange={handleSelectionChange}
    />
  );
};

export default SelectArtist;