import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CircularProgress,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";

// Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { DataGrid } from "@mui/x-data-grid";

function SingleSeries() {
  const { id } = useParams();
  const auth = localStorage.getItem("authToken");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [values, setValues] = useState(null);
  const [orderHistory, setOrderHistory] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getUserHistory();
    }
    if (auth) {
      const decodedJwt = JSON.parse(atob(auth.split(".")[1]));
      if (decodedJwt.exp * 1000 < Date.now()) {
        navigate("/sign-in");
      }
    }
  }, [id, auth]);

  const getUserHistory = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/admin/product/getcataloguesdata`,
        {
          series_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      );
      if (response.data.success) {
        setOrderHistory(response.data.data);
        enqueueSnackbar("Series Fetched successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Failed", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 200,
    },
    {
      field: "catalogue_id",
      headerName: "Catalogue Id",
      width: 200,
      valueGetter: (params) => params.row?.catalogue_id,
    },
    {
      field: "billing_id",
      headerName: "Billing Id",
      width: 200,
      valueGetter: (params) => params.row?.billing_id,
      // align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    // {
    //   field: "onclick",
    //   headerName: "Action",
    //   width: 200,
    //   renderCell: (params) => (
    //     <strong>
    //       <MDButton
    //         variant="contained"
    //         size="small"
    //         onClick={() => {
    //           navigate(`/orders/${params.row.order_id}`);
    //         }}
    //       >
    //         Open
    //       </MDButton>
    //     </strong>
    //   ),
    // },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar hidebreadcrumbTitle />
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <MDBox pt={3} pb={3}>
            <Grid container spacing={6}>
              {/* {values && ( */}
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="secondary"
                      borderRadius="lg"
                      coloredShadow="info"
                      display="flex"
                      justifyContent="space-between"
                    >
                      <MDTypography variant="h6" color="white">
                        {id}
                      </MDTypography>
                    </MDBox>

                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Card variant="outlined">
                          <MDBox pt={3}>
                            {loading1 ? (
                              <CircularProgress
                                style={{
                                  marginTop: "80px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100vh",
                                }}
                                color="inherit"
                              />
                            ) : (
                              <>
                                {orderHistory && (
                                  <MDBox sx={{ height: 400 }} p={2}>
                                    <DataGrid
                                      getRowId={(row) => row.catalogue_id}
                                      rows={orderHistory}
                                      columns={columns}
                                      initialState={{
                                        pagination: {
                                          paginationModel: {
                                            pageSize: 100,
                                          },
                                        },
                                      }}
                                      // pageSizeOptions={[5, 10, 20, 50]}
                                      disableRowSelectionOnClick
                                    />
                                  </MDBox>
                                )}
                              </>
                            )}
                          </MDBox>
                        </Card>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              {/* )} */}
            </Grid>
          </MDBox>
        </>
      )}
    </DashboardLayout>
  );
}

export default SingleSeries;