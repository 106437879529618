import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CircularProgress,
  Grid,
  FormControlLabel,
  Typography,
  TextField,
  Chip,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";

// Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import Checkbox from "@mui/material/Checkbox";
import SelectArtist from "components/SelectArtist/SelectArtist";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import MDBadge from "components/MDBadge";
import { DataGrid } from "@mui/x-data-grid";
import {
  DeleteForeverOutlined,
  DeleteOutlineOutlined,
} from "@mui/icons-material";

import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import Autocomplete from "@mui/material/Autocomplete";
import MDDialog from "components/MDDialog/MDDialog";

const seriesData = [
  { series_id: 1, series_name: "PRO" },
  { series_id: 2, series_name: "PRN" },
  { series_id: 3, series_name: "DAG" },
  { series_id: 4, series_name: "DAP" },
  { series_id: 5, series_name: "BMC" },
];

function StoreSale() {
  const auth = localStorage.getItem("authToken");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [values, setValues] = useState();

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setValues(e.target.value);
  };
// Function to convert UTC to IST
const convertUTCtoIST = (utcDate) => {
  // const timeZone = 'Asia/Kolkata'; // IST time zone
  const timeZone = 'UTC';
  const zonedDate = toZonedTime((utcDate), timeZone); // Convert UTC to IST
  return format(zonedDate, 'yyyy-MM-dd HH:mm:ss'); 
};
  const [stores, setStores] = useState([]);
  const columns = [
    {
      field: "order_id",
      headerName: "Order ID",
      width: 150,
      valueGetter: (params) => params.row.order.order_id,
    },
    {
      field: "order_date",
      headerName: "Order Date",
      width: 200,
      valueGetter: (params) => convertUTCtoIST(params.row?.order?.order_date), 
      align: "center",
      headerAlign: "center",
    },
    {
      field: "catalogue_ids",
      headerName: "Catalogue IDs",
      width: 400,
      valueGetter: (params) => 
        params.row?.catalogues?.map(catalogue => catalogue.catalogue_id).join(', ') || '',
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => (
        <MDBadge
          badgeContent={params.row?.order?.status}
          container
          color={params.row?.order?.status === "store sales" ? "success" : "error"}
        />
      ),
    },
  ];
  useEffect(() => {
    getUsers();
  }, []);
  const getUsers = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BASEURL}/order/getallstoresales`,
        null,
        {
          headers: { Authorization: `Bearer ${auth}` },
        }
      );
      if (res.data.success) {
        // setStores(Object.values(res.data.data).map((item) => item));
        setStores(
          Object.values(res.data.data).map((item, index) => ({
            ...item,
            id: item.catalogue_id || index, // Use catalogue_id or fallback to index if not available
          }))
        );
      } else {
        if (res.data.data === "no record found") {
          enqueueSnackbar(res.data.data, { variant: "info" });
        } else {
          console.log("Error Occurred");
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  console.log(stores)
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!values) {
      enqueueSnackbar("Please Provide All The Details !!!", {
        variant: "error",
      });
      return;
    }
    setLoading(true);


    await axios({
      method: "put",
      url: `${process.env.REACT_APP_BASEURL}/order/orderofflinewithcatids`,
      data: { cat_ids: values.split(",") },
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar("Operation successful", {
            variant: "success",
          });

          navigate(`/products`);
        } else {
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
      })
      .catch((err) => {
        console.error("Error occurred:", err);
        enqueueSnackbar("Error occurred. Please try again.", {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        handleSubmit(e);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [values]); 

  return (
    <DashboardLayout>
      <DashboardNavbar hidebreadcrumbTitle />

      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Store Sale
                </MDTypography>
              </MDBox>

              <MDBox p={3} component="form" role="form">
                <MDInput
                  label="Enter (,) separated list of catalogue IDs"
                  value={values}
                  multiline
                  rows={3}
                  onChange={handleChange}
                  fullWidth
                />
              </MDBox>

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                p={2}
               
              >
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={20} sx={{ color: "inherit" }} />
                  ) : (
                    "Create"
                  )}
                </MDButton>
              </Grid>
              <MDBox pt={3}>
                {loading ? (
                  <CircularProgress
                    style={{
                      marginTop: "80px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                    color="inherit"
                  />
                ) : (
                  <>
                    {stores && (
                      <MDBox sx={{ height: 400 }} p={2}>
                        <DataGrid
                          // getRowId={(row) => row.user_id}
                          rows={stores}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 100,
                              },
                            },
                          }}
                         
                          disableRowSelectionOnClick
                        />
                      </MDBox>
                    )}
                  </>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default StoreSale;
