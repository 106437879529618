// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import { CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";

import axios from "axios";

function AllSeries() {
  const auth = localStorage.getItem("authToken");

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    {
      field: "series_id",
      headerName: "ID",
      width: 100,
    },
    {
      field: "series_name",
      headerName: "Series Name",
      width: 200,
    },
    {
      field: "Total_Copies_avlb",
      headerName: "Total Copies Available",
      width: 200,
    },
    {
      field: "Latest_copy_ID",
      headerName: "Latest Copy ID",
      width: 200,
    },
    {
      field: "onclick",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <strong>
          <MDButton
            variant="contained"
            size="small"
            onClick={() => {
              navigate(`/series/${params.row.series_name}`);
            }}
          >
            Open
          </MDButton>
        </strong>
      ),
    },
    // {
    //   field: "onclick",
    //   headerName: "Action",
    //   width: 200,
    //   renderCell: (params) => (
    //     <strong>
    //       <MDButton
    //         variant="contained"
    //         size="small"
    //         color="error"
    //         onClick={() => {
    //           if (window.confirm("Do you really want to delete this artist?")) {
    //             handleDeleteArtist(params.row.artist_id);
    //           }
    //         }}
    //       >
    //         Delete
    //       </MDButton>
    //     </strong>
    //   ),
    // },
  ];

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/admin/series/getall`,
        null,
        {
          headers: { Authorization: `Bearer ${auth}` },
        }
      );
      if (res.data.success) {
        setUsers(Object.values(res.data.data).map((item) => item));
      } else {
        if (res.data.data === "no record found") {
          enqueueSnackbar(res.data.data, { variant: "info" });
        } else {
          console.log("Error Occurred");
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

//   const handleDeleteArtist = async (index) => {
//     setLoading(true);

//     await axios({
//       method: "post",
//       url: `${process.env.REACT_APP_BASEURL}/admin/product/deleteartist`,
//       data: { artistId: index },
//       headers: {
//         Authorization: `Bearer ${auth}`,
//       },
//     })
//       .then((res) => {
//         if (res.data.success) {
//           enqueueSnackbar("Series Deleted successfully", {
//             variant: "success",
//           });

//           getUsers();
//         } else {
//           enqueueSnackbar("Operation failed", { variant: "error" });
//         }
//       })
//       .catch((err) => {
//         console.error("Error occurred:", err);
//         enqueueSnackbar("Error occurred. Please try again.", {
//           variant: "error",
//         });
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Series
                </MDTypography>
                <MDBox className="ticket_btnsHeaderCont">
                  <MDButton
                    onClick={() => navigate("/series/add")}
                    variant="outlined"
                    color="white"
                  >
                    Add Series
                  </MDButton>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <CircularProgress
                    style={{ marginTop: "80px" }}
                    color="inherit"
                  />
                ) : (
                  <>
                    {users && (
                      <MDBox sx={{ height: 400 }} p={2}>
                        <DataGrid
                          getRowId={(row) => row.series_id}
                          rows={users}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 100,
                              },
                            },
                          }}
                         
                          disableRowSelectionOnClick
                        />
                      </MDBox>
                    )}
                  </>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AllSeries;
