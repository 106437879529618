// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import { CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";

import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import axios from "axios";

import MDBadge from "components/MDBadge";

function AllOrders() {
  const auth = localStorage.getItem("authToken");

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  // Function to convert UTC to IST
  const convertUTCtoIST = (utcDate) => {
  // const timeZone = 'Asia/Kolkata'; // IST time zone
  const timeZone = 'UTC';
  const zonedDate = toZonedTime((utcDate), timeZone); // Convert UTC to IST
  return format(zonedDate, 'yyyy-MM-dd HH:mm:ss'); 
};
  const columns = [
    {
      field: "billing_id",
      headerName: "Order ID",
      width: 150,
    },
    {
      field: "user_data.name",
      headerName: "Customer Name",
      width: 200,
      valueGetter: (params) => params.row?.user_data?.name,
    },
    {
      field: "phone_no",
      headerName: "Phone No",
      width: 200,
      valueGetter: (params) => params.row?.user_data?.phone_no,
    },
   {
      field: "billing_amount",
      headerName: "Order Value",
      width: 200,
      valueGetter: (params) => params.row?.billing_amount,
      align: "center",
    },
   {
      field: "order_date",
      headerName: "Order Date",
      width: 200,
      valueGetter: (params) => convertUTCtoIST(params.row?.order_date), 
      align: "center",
    },
       // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 100,
    // },
     {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <MDBadge
          badgeContent={params.row.status === "ordered" ? "⁠Ordered" : params.row.status === "shipped" ? "Shipped" : params.row.status === "delivered" ? "Delivered" : params.row.status}
          container
          color={params.row.status === "ordered" ? "success" :  params.row.status === "shipped" ? "warning" : params.row.status === "delivered" ? "error" : "info"}
        />
      ),
    },
    {
      field: "onclick",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <strong>
          <MDButton
            variant="contained"
            size="small"
            onClick={() => {
              navigate(`/orders/${params.row.order_id}`);
            }}
          >
            Open
          </MDButton>
        </strong>
      ),
    },
  ];

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [series, setSeries] = useState([]);
  const [currentSeries, setCurrentSeries] = useState("");

  useEffect(() => {
    getProducts();
    getSeries();
  }, []);

  const getProducts = async (series) => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BASEURL}/order/getfilteredorders`,
        { series_id: series },
        {
          headers: { Authorization: `Bearer ${auth}` },
        }
      );
      if (res.data.success) {
        // setOrders(Object.values(res.data.data.orders).map((item) => item));
        // setOrders(Object.values(res.data.data.orders).reverse().map((item) => item));
         const sortedOrders = Object.values(res.data.data.orders).sort((a, b) =>
          b.billing_id.localeCompare(a.billing_id)
        );
        setOrders(sortedOrders);
      } else {
        if (res.data.data === "no record found") {
          enqueueSnackbar(res.data.data, { variant: "info" });
        } else {
          console.log("Error Occurred");
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const getSeries = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/admin/series/getall`,
        null,
        {
          headers: { Authorization: `Bearer ${auth}` },
        }
      );
      if (res.data.success) {
        setSeries(Object.values(res.data.data).map((item) => item));
      } else {
        if (res.data.data === "no record found") {
          enqueueSnackbar(res.data.data, { variant: "info" });
        } else {
          console.log("Error Occurred");
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Orders
                </MDTypography>
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel sx={{ color: "white !important" }}>
                    Select Series ID
                  </InputLabel>
                  <Select
                    labelId="series-select-label"
                    value={currentSeries}
                    onChange={(e) => {
                      getProducts(e.target.value);
                    }}
                    label="Select Series ID"
                    sx={{ height: 35 }}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {series.map((s) => (
                      <MenuItem key={s.series_id} value={s.series_id}>
                        {s.series_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <CircularProgress
                    style={{ marginTop: "80px" }}
                    color="inherit"
                  />
                ) : (
                  <>
                    {orders && (
                      <MDBox sx={{ height: 400 }} p={2}>
                        <DataGrid
                          getRowId={(row) => row.order_id}
                          rows={orders}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 100,
                              },
                            },
                          }}
                         
                          disableRowSelectionOnClick
                        />
                      </MDBox>
                    )}
                  </>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AllOrders;
